<template>
  <div id="game-winlose" class="p-2 mt-2">
    <h6 class="mb-1">สรุป ได้-เสีย</h6>

    <DateFilter @submit="search" />

    <div class="position-relative">
      <div class="px-0">
        <div v-if="dateText.start===dateText.end">
          ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span>
        </div>
        <div v-else>
          ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
          ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
          <span> ถึง
            <span class="text-primary">{{dateText.end}}</span>
          </span>
        </div>
      </div>

      <table class="table table-sm table-bordered table-hover table-info mb-0">
        <thead>
          <tr>
            <th width="50">ลำดับ</th>
            <th colspan="2">เกม</th>
            <th width="80">ครั้ง</th>
            <th width="120">ยอดเล่น</th>
            <th width="120">คอมมิชชั่น</th>
            <th width="120">ชนะ</th>
            <th width="120">ได้-เสีย</th>
            <th width="60">ดู</th>
          </tr>
        </thead>
        <tbody v-if="isLoading && !items.length">
          <tr>
            <td colspan="10" class="text-center">กำลังรอข้อมูล...</td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoaded && !items.length">
          <tr>
            <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(item, index) in items" :key="item._id">
            <td class="text-center">{{ index+1 }}</td>
            <td width="40" class="p-0 text-center">
              <img :src="item.game.img" height="40">
            </td>
            <td>{{ (item.game.name) }} ({{ item.game.creator.name }})</td>
            <td class="text-center">{{ item.count }}</td>
            <td class="text-right text-info">{{ item.betAmount | amountFormat(2, '0.00')}}</td>
            <td class="text-right text-info">{{ item.comAmount | amountFormat(2, '0.00')}}</td>
            <td class="text-right text-success">{{ item.payoutAmount | amountFormat(2, '0.00')}}</td>
            <td class="text-right" :class="[{'text-success': item.sumAmount > 0, 'text-secondary': item.sumAmount == 0, 'text-danger': item.sumAmount < 0}]">{{ item.sumAmount | amountFormat(2, '0.00')}}</td>
            <td class="text-center">
              <button @click="viewTxns(item)" class="btn btn-outline-info btn-sm"><i class="fas fa-list"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="items.length">
          <tr>
            <td class="text-center" colspan="3">รวม</td>
            <td class="text-center">{{ summary.count }}</td>
            <td class="text-right text-info">{{ summary.betAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right text-info">{{ summary.comAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right text-success">{{ summary.payoutAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right" :class="[{'text-success': summary.sumAmount > 0, 'text-secondary': summary.sumAmount == 0, 'text-danger': summary.sumAmount < 0}]">{{ summary.sumAmount | amountFormat(2, '0.00')}}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>

      <TxnModal
        :is-show="isShowModal"
        :game="viewGame"
        :filters="filters"
        @close="closeModal"
      />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="60"
        :width="60"
        color="#CC3636"
        loader="dots"
      />
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'
import TxnModal from './components/TxnModal'

export default {
  name: 'GameWinLose',
  components: {
    DateFilter,
    TxnModal
  },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      filters: {
        startDate: null,
        endDate: null
      },
      data: null,
      isShowModal: false,
      viewGame: null
    }
  },
  computed: {
    dateText() {
      if(!this.data)
        return {
          start: null,
          end: null
        }

      return {
        start: moment(this.data.date.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.data.date.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      if(!this.data)
        return ''

      return this.data.date.total || 0
    },
    items() {
      return this.data?.items ?? []
    },
    summary() {
      return this.data?.summary ?? {
        "betAmount": 0,
        "comAmount": 0,
        "creditAmount": 0,
        "payoutAmount": 0,
        "sumAmount": 0,
        "turnOver": 0,
        "count": 0
      }
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      GameService.getWinLose(this.filters).then((response)=>{
        if(response.success) {
          this.isLoaded = true
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    viewTxns(item) {
      this.viewGame = item
      this.isShowModal = true
    },
    closeModal() {
      this.viewGame = null
      this.isShowModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
#game-winlose {
  .table {
    thead {
      tr {
        th {
          font-weight: normal;
          text-align: center;
        }
      }
    }

    tbody {
      background-color: #FFFFFF;
      tr {
        td {
          vertical-align: middle;
          font-size: 90%;
        }
      }
    }
  }
}
</style>
