<template>
  <b-modal
    id="txns-modal"
    ref="txns-modal"
    no-close-on-backdrop
    hide-footer
    @show="loadData"
    size="xl"
    dialog-class="txns-modal position-relative"
    body-class="p-0"
    header-class="align-items-center p-0"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center">
        <img v-if="gameImg" class="game-img" :src="gameImg">
        <h5 class="text-secondary ml-2 font-weight-normal mb-0 text-info">{{ gameName }}</h5>
      </div>
      <div class="p-2">
        <b-button size="sm" variant="outline-danger" @click="close()">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>

    <div class="p-2">
      <TxnTable
        :data="data"
        :perPage="perPage"
        :currentPage="currentPage"
        :isLoading="isLoading"
        :isLoaded="isLoaded"
        @page="togglePage"
      />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="60"
        :width="60"
        color="#CC3636"
        loader="dots"
      />
    </div>
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import TxnTable from './TxnTable'

export default {
  name: 'TxnModal',
  props: ['isShow', 'game', 'filters'],
  components: {
    TxnTable
  },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      data: null,
      perPage: 100,
      currentPage: 1
    }
  },
  computed: {
    gameId() {
      return this.game?.game?.gameId
    },
    gameImg() {
      return this.game?.game?.img
    },
    gameName() {
      return `${this.game?.game?.name} (${this.game?.game?.cats?.name})`
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['txns-modal'].show()
      }else{
        this.$refs['txns-modal'].hide()
      }
    }
  },
  methods: {
    loadData() {
      this.isLoading = true
      GameService.getWinLoseTxns({
        ...this.filters,
        gameId: this.gameId,
        perPage: this.perPage,
        offset: this.offset
      }).then((response)=>{
        if(response.success) {
          this.isLoaded = true
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          this.$refs['txns-modal'].hide()
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    togglePage(page) {
      this.currentPage = page
      this.loadData()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#txns-modal {
  .modal-header {
    .game-img {
      border-top-left-radius: 0.3rem;
      height: 50px;
    }
  }
}
</style>
